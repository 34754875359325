export const projectData= [
    {
        "id":"1",
        name: "Langbro",
        title: ["We are proud to ",<span style={{ color: '#F8F025' }}>contribute</span>, " to ", <span style={{ color: '#EEBECE' }}>Langbro</span>, " Success"],
        description: "Långbro Värdshus is restaurant open 24 hours and they serve our customers good food based on the flavors and ingredients of the season.",
        text: "Role - Branding, Web design and Lead generation",
        image: "/Img/chef.png",
        identifyphoto: "/Img/2.png",
        icon: "/Img/visitweb.png",
        year: 2016
    },
    {
        "id":"2",
        name: "29k",
        title: ["We are proud to ",<span style={{ color: '#F8F025' }}>contribute</span>, " to ", <span style={{ color: '#EEBECE' }}>29k</span>, " wellbeing"],
        description: "The world's first personal growth programs combining the best science based tools with live group conversations - made available wherever you are, whenever you need it.",
        text: "Role - Branding, Web design and Lead generation",
        image: "/Img/mobile.png",
        identifyphoto: "/Img/2.png",
        icon: "/Img/vistapp.png",
        year: 2017
    },
    {
        "id":"3",
        name: "29k",
        title: ["We are proud to ",<span style={{ color: '#F8F025' }}>contribute</span>, " to ", <span style={{ color: '#EEBECE' }}>29k</span>, " wellbeing"],
        description: "The world's first personal growth programs combining the best science based tools with live group conversations - made available wherever you are, whenever you need it.",
        text: "Role - Branding, Web design and Lead generation",
        image: "/Img/mobile.png",
        identifyphoto: "/Img/2.png",
        icon: "/Img/vistapp.png",
        year: 2017
    },
    {
        "id":"4",
        name: "29k",
        title: ["We are proud to ",<span style={{ color: '#F8F025' }}>contribute</span>, " to ", <span style={{ color: '#EEBECE' }}>29k</span>, " wellbeing"],
        description: "The world's first personal growth programs combining the best science based tools with live group conversations - made available wherever you are, whenever you need it.",
        text: "Role - Branding, Web design and Lead generation",
        image: "/Img/mobile.png",
        identifyphoto: "/Img/2.png",
        icon: "/Img/vistapp.png",
        year: 2017
    },
    {
        "id":"5",
        name: "29k",
        title: ["We are proud to ",<span style={{ color: '#F8F025' }}>contribute</span>, " to ", <span style={{ color: '#EEBECE' }}>29k</span>, " wellbeing"],
        description: "The world's first personal growth programs combining the best science based tools with live group conversations - made available wherever you are, whenever you need it.",
        text: "Role - Branding, Web design and Lead generation",
        image: "/Img/mobile.png",
        identifyphoto: "/Img/2.png",
        icon: "/Img/vistapp.png",
        year: 2017
    }

]



